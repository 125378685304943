<template>
  <div>
    <div class="row">
      <loading-notification :show="loading" />
    </div>
    <form @submit.prevent="submit" enctype="multipart/form-data">
      <div class="row">
        <div class="flex xs12">
          <va-select
            v-model="selectedEvangelisticMethod"
            :label="$t('reports.modal.inputs.evangelisticMethodinput')"
            :options="methodsList"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            :loading="isLoading.methods"
            :error="error.evangelisticMethod"
            text-by="i18nName"
            searchable
          />
        </div>
      </div>
      <div class="row">
        <div class="flex xs12 md6">
          <va-date-picker
            :label="$t('reports.modal.inputs.monthInput')"
            :id="getInputId('month-input')"
            :config="dateConfig"
            v-model="presentationDate"
          />
        </div>
        <div class="flex xs12 md6">
          <va-input
            color="info"
            v-model="form.location.value"
            :disabled="loading"
            :label="$t('reports.modal.inputs.locationInput')"
            :error="!!form.location.errors.length"
            :error-messages="translatedErrors(form.location.errors)"
            @blur="validate('location')"
          />
        </div>
      </div>
      <div class="row">
        <div class="flex xs12 md4">
          <va-input
            color="info"
            type="number"
            step="1"
            min="0"
            v-model="form.audience.value"
            :disabled="loading"
            :label="$t('reports.modal.inputs.audienceInput')"
            :error="!!form.audience.errors.length"
            :error-messages="translatedErrors(form.audience.errors)"
            @blur="validate('audience')"
          />
        </div>
        <div class="flex xs12 md4">
          <va-input
            color="info"
            type="number"
            step="1"
            min="0"
            v-model="form.newcomers.value"
            :disabled="loading"
            :label="$t('reports.modal.inputs.newcomersInput')"
          />
        </div>
        <div class="flex xs12 md4">
          <va-input
            color="info"
            type="number"
            step="1"
            min="0"
            v-model="form.regulars.value"
            :disabled="loading"
            :label="$t('reports.modal.inputs.regularsInput')"
          />
        </div>
      </div>
      <div class="row">
        <h5>{{ $t("reports.modal.images") }}</h5>
        <div class="flex xs12">
          <va-file-upload dropzone type="gallery" v-model="gallery" />
        </div>
      </div>
      <div class="row">
        <div class="flex xs12">
          <va-button color="primary" :disabled="loading || haveErrors">
            <text-loading :loading="loading" icon="fa fa-save">{{
              $t("layout.form.save")
            }}</text-loading>
          </va-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { validatorMixin } from "@/services/validator";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import { mapGetters } from "vuex";

export default {
  name: "presentation-form",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    report: {
      type: Object,
      required: true,
    },
    remotePresentation: {
      type: Object,
      required: false,
    },
  },
  mixins: [validatorMixin],
  data() {
    return {
      gallery: [],
      teamCode: "",
      presentation: {},
      formId: Math.random(),
      presentationDate: null,
      selectedEvangelisticMethod: "",
      methodsList: [],
      isLoading: {
        methods: false,
      },
      error: {
        evangelisticMethod: true,
        existent: false,
        invalid: false,
      },
      form: {
        location: {
          value: "",
          validate: {
            required: true,
          },
          errors: [],
        },
        audience: {
          value: "0",
          validate: {
            required: true,
            minValue: 1,
          },
          errors: [],
        },
        newcomers: {
          value: "0",
          validate: {
            required: true,
            minValue: 0,
          },
          errors: [],
        },
        regulars: {
          value: "0",
          validate: {
            required: true,
            minValue: 0,
          },
          errors: [],
        },
      },
    };
  },
  computed: {
    ...mapGetters(["currentLocale"]),
    haveErrors() {
      return (
        this.error.existent ||
        this.error.invalid ||
        this.presentationDate === null ||
        !this.selectedEvangelisticMethod
      );
    },
    dateConfig() {
      let min = null;
      let max = null;
      if (this.report.proyection_date) {
        const date = this.report.proyection_date.split("T")[0];
        min = this.$date.format(date, "yyyy-MM-dd");
        max = lastDayOfMonth(this.$date.parse(date));
      }

      return {
        minDate: min,
        maxDate: max,
      };
    },
  },
  watch: {
    reset(val) {
      if (val) this.resetPresentation();
    },
    remotePresentation(val) {
      this.setRemotePresentation(val);
    },
    currentLocale(val) {
      this.validateAll();
    },
    gallery(val) {
      this.filterGallery(val);
    },
    selectedEvangelisticMethod(val) {
      this.error.evangelisticMethod = !val;
    },
  },
  async created() {
    this.initialdata();
    this.getTeamCode(this.report.team_id);
    this.getEvangelisticMethods();
    await this.$nextTick();
    this.validateAll();
  },
  methods: {
    setRemotePresentation(val) {
      if (!val || !val.id) return;

      this.setFormData(val);
      this.presentation = Object.assign({}, val);
      this.audience = val.audience;
      this.newcomers = val.newcomers;
      this.regulars = val.regulars;
      if (val.presentation_date) {
        this.presentationDate = val.presentation_date;
      }
      if (val.evangelistic_method) {
        this.selectedEvangelisticMethod = Object.assign(
          {},
          val.evangelistic_method
        );
      }

      this.validateAll();
    },
    initialdata() {
      if (this.remotePresentation) {
        this.setRemotePresentation(this.remotePresentation);
      }
    },
    async getEvangelisticMethods() {
      await this.asyncFind("", "methods", "evangelistic-methods");
      for (const method of this.methodsList) {
        method.i18nName = this.$t(method.name);
      }
    },
    async asyncFind(query, type, route) {
      this.isLoading[type] = true;

      if (!query.includes("&sort=")) {
        query += "&sort=name";
      }
      if (!query.includes("&direction=")) {
        query += "&direction=asc";
      }

      let response = false;
      try {
        response = await this.$http.get(`/${route}?q=${query}`);
      } catch (err) {
        this.isLoading[type] = false;
        return;
      }

      this[type + "List"] = response.data.data || [];
      this.isLoading[type] = false;
    },

    async getTeamCode(teamId) {
      if (!teamId) return;
      const team = await this.$http.get(`/teams/${teamId}`);
      this.teamCode = team.data.data.code;
    },

    filterGallery(gallery) {
      const maxFileSize = 8388608;
      const fileTypes = ["image/gif", "image/png", "image/jpeg"];
      const currentGallery = gallery.filter((g) => {
        const size = g.size <= maxFileSize;
        const type = fileTypes.findIndex((t) => t === g.type) !== -1;
        return size && type;
      });

      if (currentGallery.length !== gallery.length) {
        const types = ".png, .jpg, .gif";
        const size = "8MB";
        this.showToast(this.$t("layout.images.not_allowed", { types, size }), {
          icon: "fa-times",
          duration: 3000,
          position: "top-right",
        });
        this.gallery = currentGallery.slice(0);
      }
    },
    resetPresentation() {
      this.presentation = {};
      this.presentationDate = null;
      this.setFormValue("location", "");
      this.setFormValue("audience", "0");
      this.setFormValue("newcomers", "0");
      this.setFormValue("regulars", "0");
      this.gallery = [];
    },
    getInputId(name) {
      return name + "-" + this.formId;
    },
    isValidated(fieldId) {
      let isValid = false;
      if (this.formFields[fieldId]) {
        isValid =
          this.formFields[fieldId].validated && this.formFields[fieldId].valid;
      }
      return isValid;
    },
    submit() {
      this.error.evangelisticMethod = false;
      this.validateAll();
      if (!this.formReady) return;

      if (!this.selectedEvangelisticMethod) {
        this.error.evangelisticMethod = true;
        return;
      }

      const presentation = new FormData();
      if (this.presentation.id) {
        presentation.append("id", this.presentation.id);
      }
      if (this.presentationDate) {
        presentation.append("presentation_date", this.presentationDate);
      }
      if (this.selectedEvangelisticMethod) {
        presentation.append(
          "evangelistic_method_id",
          this.selectedEvangelisticMethod.id
        );
      }
      if (this.report.team_id) {
        presentation.append("team_id", this.report.team_id);
      }

      const presentationData = this.getFormData(this.presentation);
      presentation.append("location", presentationData.location);
      presentation.append("audience", presentationData.audience);
      presentation.append("newcomers", presentationData.newcomers);
      presentation.append("regulars", presentationData.regulars);

      // var suma = parseInt(presentationData.audience) + parseInt(presentationData.newcomers) + parseInt(presentationData.regulars);
      // presentation.append('type_asistance', suma)

      const teamCode = this.teamCode;

      for (const image of this.gallery) {
        const imageExtension = image.name.split(".").pop();
        const newImage = new File(
          [image],
          `${this.teamCode}${this.presentationDate}`,
          {
            type: `image/${imageExtension}`,
          }
        );
        presentation.append("presentation_images[]", newImage);
      }

      this.$emit("submit", presentation);
      this.resetPresentation();
    },
  },
};
</script>
